.blank-blog-container {
    display: flex;
}

.blank-blog-container .content {
    margin: 250px 50px 0 50px;
}

.blank-blog-container button {
    margin-top: 20px;
}

/* filled blog */

.blog-hl-container {
    display: flex;
}

.blog-hl-container .content {
    margin: 150px 50px 25px 50px;
}

/* Blog Cuztomize  */

.blogs-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 40px;
}

.blogs-container li {
    list-style: none;
}

.blogs-container .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blogs-container img {
    max-width: 333px;
    width: 333px;
    height: 233px;
    object-fit: cover;
}

.blogs-container #divider {
    margin: 0 3px;
}

.blogs-container .blog-info {
    display: flex;
}

.blogs-container #title {

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 333px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}

.blogs-container .card {
    padding: 10px;
    margin: 10px;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: ease 0.25s;
}

.blogs-container .card:hover {
    border: 2px solid #001150;
    border-radius: 10px;
    transition: ease 0.25s;
}

.blogs-container .pagination {
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 20px 0;
}

.blogs-container .pagination button {
    background-color: #ffffff;
    border: 1px solid #ccc;
    color: #333;
    padding: 8px 12px;
    cursor: pointer;
}

.blogs-container .pagination button.selected {
    background-color: #007bff;
    color: #fff;
}

.blogs-container .pagination button.not-selected {
    background-color: #ffffff;
    color: #333;
}

.blogs-container .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    position: relative;
    z-index: -1;
}

/* Detail Blog  */

.dblog-container {
    display: flex;
}

.dblog-container .content {
    margin: 125px 50px 0 50px;
}

.dblog-container .title {
    font-size: 48px;
}

.dblog-container .info-blog {
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;
}

.dblog-container .main-image {
    max-width: fit-content;
    align-content: center;
    border-radius: 5px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 35px;
}

.dblog-container .line {
    border: none;
    border-top: 2px solid #D9DBE9;
    margin: 50px 0px 25px 0px;
}

.dblog-container .body {
    text-align: justify;
    /* margin: 10px 0px; */
}

.dblog-container .albums img {
    max-height: 200px;
    margin-right: 15px;
    transition: ease-in 0.25s;
    margin-top: 15px;
}

.dblog-container .dc-text {
    margin: 10px 0px;
}

.dblog-container .albums img:hover {
    transform: scale(1.10);
    transform-origin: left top;
    transition: ease-in 0.25s;
    margin-right: 40px;
    margin-bottom: 10px;
}

@media (max-width: 960px) {
    .blogs-container img {
        max-width: 100%;
        width: 283px;
        height: 183px;
        object-fit: cover;
    }

    .dblog-container {
        display: flex;
        justify-content: center;
        /* Mengatur konten agar berada di tengah */
    }

    .dblog-container .content {
        margin: 125px 50px 0 50px;
        max-width: 800px;
        /* Mengatur lebar maksimum konten agar tidak terlalu lebar pada resolusi besar */
    }

    .dblog-container .albums img:hover {
        transform: scale(1.10);
        transform-origin: left top;
        transition: ease-in 0.25s;
        margin-right: 30px;
        margin-bottom: 15px;
    }
}