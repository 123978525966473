.artcl-hl-container {
    display: flex;
}

.artcl-hl-container .content {
    margin: 150px 50px 25px 50px;
}

/* Article Cuztomize  */

.artcls-container {
    max-width: 1300px;

    margin: 0 40px;
}

.artcls-container .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.artcls-container img {
    max-width: 333px;
    width: 333px;
    height: 233px;
    object-fit: cover;
}

.artcls-container #divider {
    margin: 0 3px;
}

.artcls-container .artcl-info {
    display: flex;
}

.artcls-container #title {

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 333px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}

.artcls-container .card {
    padding: 10px;
    margin: 10px;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: ease 0.25s;
}

.artcls-container .card:hover {
    border: 2px solid #001150;
    border-radius: 10px;
    transition: ease 0.25s;
}

.artcls-container .pagination {
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 20px 0;
}

.artcls-container .pagination button {
    background-color: #ffffff;
    border: 1px solid #ccc;
    color: #333;
    padding: 8px 12px;
    cursor: pointer;
}

.artcls-container .pagination button.selected {
    background-color: #007bff;
    color: #fff;
}

.artcls-container .pagination button.not-selected {
    background-color: #ffffff;
    color: #333;
}

.artcls-container .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    position: relative;
    z-index: -1;
}

@media (max-width: 960px) {
    .artcls-container img {
        max-width: 100%;
        width: 283px;
        height: 183px;
        object-fit: cover;
    }
}