#c-footer-img {
    z-index: 1;
    display: flex;
    position: absolute;
    margin-left: 400px;
    margin-top: 475px;
    max-width: 80px;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

#c2-footer-img {
    z-index: -1;
    display: flex;
    position: absolute;
    max-width: 175px;
    right: 0;
    margin-bottom: 100px;
}

#c3-footer-img {
    /* z-index: 2; */
    z-index: -1;
    display: flex;
    position: absolute;
    right: 0;
    margin-top: 176.5px;
    max-width: 146.5px;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.footer-container {
    width: 100%;
}

.footer-container .content {
    display: flex;
    justify-content: space-between;
    padding-top: 275px;
    margin-left: 50px;
    margin-right: 50px;
}

.footer-container .right {
    display: flex;
}

.footer-container .c1 {
    max-width: 18em;
}

.footer-container .c2,
.footer-container .c3 {
    max-width: 10em;
    margin-right: 75px;
}

.footer-container .c4 {
    max-width: 15em;
}

.footer-container #divider {
    margin: 0 5px;
}

.footer-container .copyright {
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.footer-container .copyright a {
    text-decoration: underline;
}

.footer-container .adds {
    display: none;
}

.footer-container .title img {
    max-width: 30px;
    margin-right: 10px;
}

.footer-container .c1 .title {
    display: flex;
    align-items: center;
}

.footer-line {
    border: none;
    border-top: 1px solid #D9DBE9;
    margin: 80px 50px 20px 50px;
}

.footer-container .sosmed img {
    max-width: 22.5px;
    margin-right: 20px;
}

.footer-container .sosmed #fb {
    max-width: 15px;
}

.footer-container .c1 p {
    margin: 22.5px 0;
}

.footer-container li {
    list-style: none;
    margin-bottom: 10px;
}

.footer-container .right a {
    color: #6F6C90;
}

.footer-email,
.footer-call,
.footer-mark {
    display: flex;
    margin-bottom: 10px;
}

.footer-container .contact img {
    margin-right: 10px;
    max-width: 20px;
}

.footer-mark img {
    max-height: 22.5px;
}

.footer-container .content h4 {
    margin-bottom: 25px;
}

@media (max-width: 960px) {
    /* For Phone  */

    #c-footer-img {
        z-index: 1;
        display: flex;
        position: absolute;
        margin-left: 200px;
        margin-top: 575px;
        max-width: 80px;
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }

    .footer-container .content {
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
        padding-top: 275px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .footer-container .right {
        display: flex;
        flex-direction: column;
    }

    .footer-container .content h4 {
        margin-bottom: 10px;
    }

    .footer-container .c2,
    .footer-container .c3,
    .footer-container .c4 {
        margin-top: 30px;
    }

    .footer-container .copyright {
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 20px;
    }

    .footer-container .adds {
        display: none;
        margin-top: 5px;
    }
}