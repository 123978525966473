@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,400;0,9..40,700;1,9..40,400&family=Quicksand:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans';
  color: #001150;
}

html {
  scroll-behavior: smooth;
}

.navbar {
  position: fixed;
  width: 100%;
}

a {
  text-decoration: none;
}

.btn-fill-white {
  padding: 8px 19px;
  color: #001150;
  background-color: white;
  border-color: transparent;
  border-width: 1px;
  transition: ease 0.35s;
}

/* .btn-fill-white:hover {
  padding: 8px 19px;
  color: white;
  background-color: #001150;
  border-color: transparent;
  border-width: 1px;
  transition: ease 0.35s;
} */

.btn-fill-blue {
  padding: 8px 19px;
  color: white;
  background-color: #001150;
  border-color: transparent;
  border-width: 1px;
  transition: ease 0.35s;
}

.btn-fill-blue:hover {
  padding: 8px 19px;
  color: #001150;
  background-color: transparent;
  border-color: #001150;
  border-style: solid;
  border-width: 1px;
  transition: ease 0.35s;
}

.btn-outline-white {
  padding: 8px 19px;
  color: white;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  transition: ease 0.35s;
}

.btn-outline-white:hover {
  padding: 8px 19px;
  color: #001150;
  background-color: white;
  border-color: transparent;
  border-width: 1px;
  transition: ease 0.35s;
}

.btn-outline-blue {
  padding: 8px 19px;
  color: #001150;
  background-color: transparent;
  border-color: #001150;
  border-style: solid;
  border-width: 1px;
  transition: ease 0.35s;
}

.btn-outline-blue:hover {
  padding: 8px 19px;
  color: white;
  background-color: #001150;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  transition: ease 0.35s;
}

.btn-outline-gray {
  padding: 8px 19px;
  color: #6F6C90;
  background-color: transparent;
  border-color: #6F6C90;
  border-style: solid;
  border-width: 1px;
}

.btn-outline-gray:hover {
  padding: 8px 19px;
  color: white;
  background-color: #6F6C90;
  border-color: transparent;
  border-width: 1px;
  transition: ease 0.35s;
}

p {
  color: #6F6C90;
}

.line {
  border: none;
  border-top: 2px solid #D9DBE9;
  margin: 75px 50px;
}

/* Pagination  */

.pagination {
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #ffffff;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 12px;
  cursor: pointer;
}

.pagination button.selected {
  background-color: #007bff;
  color: #fff;
}

.pagination button.not-selected {
  background-color: #ffffff;
  color: #333;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  position: relative;
  z-index: -1;
}