/* Headline */

.hl-container {
    background-image: url("../../images/background/headline.png");
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.hl-container .content {
    margin: 200px 600px 200px 50px;
}

.hl-container .content h1 {
    color: white;
    margin-bottom: 5px;
    font-size: 48px;
}

.hl-container .content p {
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 50px;
    line-height: 1.6;
}

.hl-container .gap {
    margin-right: 17px;
}

/* Tentang Perusahaan  */
.boutus-container {
    padding-top: 75px;
    margin-right: 50px;
    margin-left: 50px;
}

.boutus-container .description p {
    margin-top: 20px;
}

/* Partner Kami */
.partner-container {
    margin: 45px 50px 0 50px;
}

.partner-container .logo {
    display: flex;
    margin-top: 25px;
    align-items: center;
}

.partner-container img {
    max-height: 40px;
    max-width: 100%;
    margin-right: 50px;
}

/* Lokasi Operasi  */
.location-container {
    margin: 75px 50px 0 50px;
}

section .location-container {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
}

.location-container .cards {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.location-container img {
    max-height: 300px;
    margin-right: 25px;
}

.location-container p {
    margin-top: 15px;
}

.location-container .card {
    display: flex;
    max-width: 470px;
    margin-bottom: 80px;
    margin-right: 100px;
}

.location-container .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Pencapaian Kami  */
.achieve-container {
    margin: 0 50px 80px 50px;
}

.achieve-container .cards {
    margin-top: 50px;
}

.achieve-container img {
    max-height: 250px;
    margin-right: 40px;
}

.achieve-container .card {
    display: flex;
    margin-bottom: 75px;
}

.achieve-container .detail {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.achieve-container .detail h2 {
    max-width: 300px;
    margin-bottom: 10px;
}

.achieve-container .detail p {
    font-size: 18px;
}

.achieve-container a:hover {
    cursor: default;
}

/* Hubungi Kami */
.call-container {
    background-image: url("../../images/background/call.png");
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-top: 75px;
}

.call-container h1,
.call-container p {
    color: white;
}

.call-container p {
    margin-bottom: 60px;
}

.call-container h1 {
    font-size: 56px;
}

.call-container img {
    max-width: 40px;
    height: 100%;
    margin-right: 25px;
}

.call-container .title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.call-container .content {
    margin: 50px 50px;
}

/* Layanan Kami  */
.service-container {
    margin: 0 50px;
    padding-top: 75px;
}

.service-container img {
    max-width: 75px;
    margin-bottom: 10px;
}

.service-container #horn {
    max-width: 60px;
}

.service-container .cards {
    display: flex;
    margin-top: 45px;
}

.service-container .card-horn {
    margin-right: 100px;
    max-width: 300px;
}

.service-container .card-car {
    max-width: 260px;
}

.service-container button {
    margin-top: 50px;
}

/* Tim Kami  */
.our-team-container {
    margin: 0 50px;
}

.our-team-container img {
    max-width: 175px;
}

.our-team-container h1 {
    margin-bottom: 50px;
}

.our-team-container .content {
    display: flex;

}

.our-team-container .card {
    text-align: center;
    margin-right: 40px;
}

.our-team-container #name {
    margin: 10px 0 5px 0;
}

/* Visi Misi  */

.vismis-container {
    display: flex;
    margin: 0 50px;
}

.vismis-container h2 {
    margin-bottom: 30px;
}

.vismis-container p {
    margin-bottom: 20px;
}

.vismis-container .vision,
.vismis-container .mission {
    max-width: 450px;
}

.vismis-container .vision {
    margin-right: 200px;
}

@media (max-width: 960px) {

    /* For Phone  */

    /* Headline  */
    .hl-container .content h1 {
        color: white;
        margin-bottom: 5px;
        font-size: 36px;
    }

    .hl-container .content p {
        color: rgba(255, 255, 255, 0.75);
        margin-bottom: 50px;
        line-height: 1.6;
    }

    .hl-container .button {
        display: flex;
    }


    /* Lokasi Operasi  */
    .location-container {
        margin: 75px 50px 0 50px;
    }

    section .location-container {
        min-height: 50vh;
        display: flex;
        flex-direction: column;
    }

    .location-container .cards {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .location-container img {
        max-height: 300px;
        margin-right: 20px;
        margin-bottom: 10px;
        object-fit: cover;

    }

    .location-container p {
        margin: 15px 0;
    }

    .location-container .card {
        display: flex;
        flex-direction: column;
        max-width: 470px;
        margin-bottom: 80px;
        margin-right: 100px;
    }

    .location-container .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    /* Pencapaian Kami  */
    .achieve-container {
        margin: 0 50px 80px 50px;
    }

    .achieve-container .cards {
        margin-top: 50px;
    }

    .achieve-container img {
        max-height: 250px;
        margin-bottom: 25px;
    }

    .achieve-container .card {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }

    .achieve-container .detail {
        max-width: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .achieve-container .detail h2 {
        max-width: 300px;
        margin-bottom: 10px;
    }

    .achieve-container .detail p {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .achieve-container a:hover {
        cursor: default;
    }

    /* Partner Kami */
    .partner-container {
        margin: 45px 50px 0 50px;
    }

    .partner-container .logo {
        display: flex;
        margin-top: 25px;
        align-items: center;
        flex-wrap: wrap;

    }

    .partner-container img {
        max-height: 22px;
        max-width: 100%;
        margin-right: 30px;
        margin-bottom: 20px;
    }

    /* Layanan Kami  */
    .service-container .cards {
        display: flex;
        margin-top: 45px;
        flex-direction: column;
    }

    .service-container .card-horn {
        margin-right: 100px;
        max-width: 300px;
        margin-bottom: 75px;
    }

    .service-container .card-car {
        max-width: 200px;
    }

    .service-container button {
        margin-top: 25px;
    }

    /* Tim Kami  */
    .our-team-container img {
        max-width: 90px;
        margin-right: 15px;

    }

    .our-team-container .content {
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
    }

    .our-team-container #name {
        margin: 0 0 5px 0;
    }

    .our-team-container .card {
        text-align: left;
        margin-right: 20px;
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
    }

    /* Visi Misi  */
    .vismis-container {
        display: flex;
        flex-direction: column;
        margin: 0 50px;
    }

    .vismis-container .vision,
    .vismis-container .mission {
        max-width: 450px;
        margin-bottom: 25px;
    }

    .vismis-container .vision {
        margin-right: 70px;
    }

}