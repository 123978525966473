.nav-container {
    padding: 1.7em 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: ease-in 0.25s;
    height: 60px;
}

.nav-container a {
    color: white;
    margin: 15px;
}

.nav-container .menu-icon i {
    color: white;
    margin: 15px;
    font-size: 20px;
}

.nav-container .menu-icon {
    display: none;
}

.nav-container img,
.nav-bg img {
    max-width: 35px;
}

.nav-container a:hover {
    border-bottom: 2px solid white;
    padding-bottom: 5px;
    transition: ease-in-out 0.075s;
}

.nav-container a::after {
    border-bottom: 2px solid white;
    padding-bottom: 5px;
}

/* Go Back Nav  */

.back-nav-container a {
    margin: 15px;
    color: #001150;
}

.back-nav-container a:hover {
    border-bottom: 2px solid #001150;
    padding-bottom: 5px;
    transition: ease-in-out 0.075s;
}

.back-nav-container img {
    max-width: 35px;
}

.back-nav-container {
    padding: 1.7em 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
}

.blank-nav-container img {
    max-width: 35px;
}

.blank-nav-container {
    padding: 1.7em 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
}

.nav-bg {
    padding: 1em 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    transition: ease-out 0.25s;
    height: 50px;
}

.nav-bg .menu-icon {
    display: none;
}

.nav-bg a {
    margin: 15px;
}

.nav-bg a:hover {
    border-bottom: 2px solid #001150;
    padding-bottom: 5px;
    transition: ease-in-out 0.075s;
}

.nav-bg a::after {
    border-bottom: 2px solid #001150;
    padding-bottom: 5px;
}

.nav-bg .menu-icon i {
    margin: 15px;
    font-size: 20px;
}

/* .nav-bg .menu-icon img,
.nav-container .menu-icon img {
    max-width: 30px;
} */

.nav-bg .menu-icon {
    display: none;
}


@media (max-width: 960px) {

    .nav-container .links {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        text-align: center;
        opacity: 1;
        transition: all 0.5 ease;
    }

    .nav-container .links-active {
        background-color: white;
        /* left: -10; */
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 1;
        margin-top: 500px;
        width: 100%;
    }

    .nav-container .links-active a {
        padding: 1rem;
        width: 87%;
        display: table;
        color: #001150;
    }

    .nav-container .links-active a:hover {
        background-color: #bac9ff;
        border-radius: 0;
    }

    .nav-container .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%, 70%);
        cursor: pointer;
    }

    .nav-container .logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(150%, 60%);
    }

    /* scrolled nav  */
    .nav-bg .links {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        text-align: center;
        opacity: 1;
        transition: all 0.5 ease;
    }

    .nav-bg .links-active {
        background-color: white;
        /* left: -10; */
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 1;
        margin-top: 500px;
        width: 100%;
    }

    .nav-bg .links-active a {
        padding: 1rem;
        width: 87%;
        display: table;
        color: #001150;
    }

    .nav-bg .links-active a:hover {
        background-color: #bac9ff;
        border-radius: 0;
    }

    .nav-bg .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%, 60%);
        cursor: pointer;
    }

    .nav-bg .logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(150%, 50%);
    }

}