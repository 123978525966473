.detail-desc-container {
    display: flex;
}

.detail-desc-container .content {
    margin: 125px 50px 0 50px;
}

.detail-desc-container p {
    margin-top: 20px;
    color: #001150;
}

.detail-desc-container h1 {
    font-size: 40px;
}

/* Angkutan Sewa Khusus */

.sr-location-container {
    margin: 75px 50px 0 50px;
}

section .sr-location-container {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
}

.sr-location-container .cards {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.sr-location-container img {
    max-height: 250px;
    margin-right: 25px;
}

.sr-location-container p {
    margin-top: 15px;
}

.sr-location-container .card {
    display: flex;
    max-width: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 100px;
}

.sr-location-container .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sr-location-container #title {
    font-size: 32px;
}

/* Periklanan Videotron  */

.adds-location-container {
    margin: 75px 50px 0 50px;
}

section .adds-location-container {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
}

.adds-location-container .cards {
    display: flex;
    flex-wrap: wrap;
}

.adds-location-container img {
    max-height: 250px;
    margin-right: 25px;
}

.adds-location-container p {
    margin-top: 15px;
}

.adds-location-container .card {
    display: flex;
    max-width: 500px;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-right: 100px;
}

.adds-location-container .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.adds-location-container #title {
    font-size: 32px;
}

.adds-location-container .albums img {
    max-height: 130px;
    margin-right: 15px;
    transition: ease-in 0.25s;
    margin-top: 15px;
}

.adds-location-container .albums img:hover {
    transform: scale(1.25);
    transform-origin: left top;
    transition: ease-in 0.25s;
    margin-right: 60px;
}

/* Benefit Iklan  */


.adds-benefit-container {
    margin: 75px 50px 0 50px;
}

section .adds-benefit-container {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
}

.adds-benefit-container .cards {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.adds-benefit-container img {
    max-height: 50px;
    margin-right: 25px;
}

.adds-benefit-container p {
    margin-top: 15px;
}

.adds-benefit-container .card {
    max-width: 400px;
    margin-top: 25px;
    margin-bottom: 20px;
    margin-right: 100px;
}

.adds-benefit-container h1 {
    font-size: 32px;
}

.adds-benefit-container h2 {
    margin: 5px 0;
}

/* Tawaran  */

.adds-offer-container {
    margin: 75px 50px 0 50px;
    max-width: 800px;
}

.adds-offer-container p {
    margin: 15px 0 35px 0;
}

@media (max-width: 960px) {

    /* For Phone  */

    .adds-location-container .card {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin-top: 20px;
        margin-bottom: 15px;
        margin-right: 100px;
    }

    .adds-location-container p {
        margin: 10px 0 20px 0;
    }

    .adds-location-container #title {
        font-size: 32px;
    }

    .adds-location-container .albums img {
        max-height: 90px;
        margin-right: 10px;
        transition: ease-in 0.25s;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .adds-location-container img {
        max-height: 250px;
        margin-right: 25px;
        margin-bottom: 10px;
    }

    /* Angkutan Sewa Khusus */
    .sr-location-container p {
        margin: 10px 0 20px 0;
    }

    .sr-location-container .card {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 100px;
    }

    .sr-location-container img {
        max-height: 250px;
        margin-right: 25px;
        margin-bottom: 15px;
    }

}